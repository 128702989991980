import React from 'react'
import './Header.css'
import Logo from '../../Assets/logo.png'
import { GrMail } from 'react-icons/gr';
// import { BsTelephoneFill } from 'react-icons/bs';
// import { IoLogoWhatsapp } from 'react-icons/io';
import Watsapp from '../Watsapp';
import Telephone from '../Telephone'


const Header = () => {

    return (
        <>
        <Watsapp/>
        <Telephone/>
            <div>
                <header>
                    <nav className="navbar navbar-expand-lg navbar-light navigation">
                        <div>
                            <a className="navbar-brand logo" href="/">
                                <img className='cryptoLogo' src={Logo} alt="cryptowalet" />
                            </a>
                        </div>

                        <button className="navbar-toggler hamburger" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" data- toggle="collapse" data-target=".navbar-collapse">
                            <span className="mymenu"></span>
                            <span className="mymenu"></span>
                            <span className="mymenu"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-around" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto ulist  ">
                                <li className="nav-item ">
                                    <a href='/'>Home</a>
                                </li> 
                                <li className="nav-item ">
                                    <a href='#meetlokah'>Meet Lokah</a>
                                </li> 
                                <li className="nav-item ">
                                    <a href='#whylokah'>Why Lokah</a>
                                </li> 

                                <li className="nav-item">
                                <a href='#servicesdiv'>Services</a>
                                </li>
                                <li className="nav-item">
                                    <a href='#contactdiv'>Contact Us</a>
                                </li>
                                <li className="nav-item">
                                    <a href='mailto:info@lokah.co.in' class="nav-link icons" style={{ color: '#131313', fontWeight: '600' }}><GrMail size={18} color='#232323' style={{ marginRight: '5px' }} />info@lokah.co.in</a>
                                </li>
                               
                            </ul>
                        </div>
                    </nav>
                </header>
            </div>
        </>
        // <div className=''>
        //     <nav class="  headerMain navbar navbar-expand-lg fixed-top   ">
        //         <div class="container-fluid  gap-5">
        //             <img class='logoimg mt-3' src={Logo} alt='not visible' />
        //             <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        //                 <span class="navbar-toggler-icon"></span>
        //             </button>
        //             <div class="collapse navbar-collapse mt-3 d-flex justify-content-around  " id="navbarSupportedContent">
        //                 <ul class="  headerMenuItems  navbar-nav me-auto mb-2 mb-lg-0 ">
        //                     <li class="nav-item ">
        //                         <a class="nav-link" href="#">Home</a>
        //                     </li>
        //                     <li class="nav-item">
        //                         <a class="nav-link" href="#">Meet Lokah</a>
        //                     </li>
        //                     <li class="nav-item">
        //                         <a class="nav-link" href="#">Service</a>
        //                     </li>
        //                     <li class="nav-item">
        //                         <a class="nav-link" href="#">Visit Us</a>
        //                     </li>
        //                     {/* <li class="nav-item">
        //                         <a class="nav-link" href="#">Resources</a>
        //                     </li> */}
        //                     <div>
        //                         <a href='mailto:info@lokah.co.in' class="nav-link icons" style={{color:'#131313',fontWeight:'600'}}><GrMail size={18} color='#232323' style={{ marginRight: '5px' }} />info@lokah.co.in</a>
        //                     </div>
        //                     <div>
        //                         <a href='tel:9963525491' class="nav-link icons" style={{color:'#131313',fontWeight:'600'}}><BsTelephoneFill size={18} color='#3dbae6' style={{ marginRight: '5px', marginTop: '-4px' }} />IND : +91 9963525491</a>
        //                     </div>
        //                     <div>
        //                         <a href="https://wa.link/sqsle0" target="_blank" class="nav-link icons" style={{color:'#131313',fontWeight:'600'}}><IoLogoWhatsapp size={18} color='green' style={{ marginRight: '5px' }} />IND : +91 9963525491</a>
        //                     </div>
        //                 </ul>
        //             </div>
        //         </div>
        //     </nav>
        // </div>
    )
}

export default Header