import React from 'react'
import './CanadaPR.css'
import formImg from '../../Assets/cannadatwo.png'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import map from '../../Assets/map.png'
import paperwork from '../../Assets/paperwork.png'
import callanytime from '../../Assets/callanytime.png'
import emailus from '../../Assets/emailus.png'
import officeaddress from '../../Assets/officeaddress.png'
import { HiPhone } from 'react-icons/hi'
import { BsWhatsapp } from 'react-icons/bs'
import { RiArrowDropDownFill } from 'react-icons/ri';
import { GoPrimitiveDot } from 'react-icons/go';
const CanadaPR = () => {
    return (
        <>
            <Header />
            <div class='appmainDiv d-flex flex-column align-items-center'>
                <div class='MainDivCanadapr d-flex justify-content-center'>
                    <div class='cannadaPrTextimgtransDiv d-flex flex-column flex-md-row justify-content-center justify-content-md-around align-items-center cannadaprTexteTransimg'>
                        <p class='textOne'>Canada PR</p>
                        {/* <div class="imgtransDiv">

                        </div> */}
                    </div>
                </div>

                <div class='cannadaprTextSecondDiv d-flex flex-column flex-md-row justify-content-center'>
                    <div class='cannadaprTextOneDiv d-flex-justify-conent-center'>
                        <div class='cannadaprTextOneSubDiv d-flex justify-content-center justify-content-md-start'>
                            <h1>Canada PR</h1>
                        </div>
                        <p>A permanent visa for Canada permits its holder to remain there indefinitely. Like the United States' Green Card, it is an immigration visa. Once a person gets a residency permit for Canada, he/she is free to relocate, live, work, and attend school in any province. The immigrant will be regarded as a Permanent Resident of Canada.
                            A person with Canada PR will be issued a Permanent Resident Card as soon as their visa is accepted. This document, also known as a Canadian PR Card, serves as proof of their legal status as a resident of Canada. One shall always carry it with them, especially when travelling.</p>
                        <p>  If the child of a person who is applying for Canadian PR is under 18 years old, they may still apply with them if the person is at least 18 years old.
                            One shall apply for a permanent resident visa for Canada under a variety of immigration programs or visa types.
                        </p>
                    </div>
                    <div class='cannadaprImageTwoDiv d-flex align-items-center justify-content-center'>
                        <img class='formImgSize' src={formImg} alt='notvisible' />
                    </div>
                </div>
                <div class='DocumentsRequired d-flex flex-column align-items-center'>
                    <div class='d-flex justify-content-center  mt-3'>
                        <h2 class='DocReqHead'>Documents Required</h2>
                    </div>
                    <div class='documentsDataMainDIv d-flex flex-column-reverse flex-md-column align-items-center'>
                        {/* <div class='DocumentReqMabdateDiv'>
                            <div class='d-flex  justify-content-start gap-2'>
                                <div class='d-flex flex-column align-items-start justify-content-between'>
                                    <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                    <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                    <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                </div>
                                <div class='d-flex flex-column align-items-start gap-2 Mandate'>
                                    <span class='mandateText'> Mandate bachelor degree</span>
                                    <span class='mandateText'>  Relevant licence required</span>
                                    <span class='mandateText'>  Relevant experience if required</span>
                                </div>
                            </div>
                        </div> */}
                        <div class='documentTextMAinwarp d-flex flex-column flex-md-row justify-content-center align-items-center mt-4'>
                            <div class='documentTextwraponeDiv d-flex flex-column flex-md-row gap-2'>
                                {/* <div class='documentTextwraponeSubDivone d-flex flex-column align-items-start'>
                                    <div class='documentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='documentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Duration of Stay</span>
                                            <p>Three Years, Extendable to Six Years</p>
                                        </div>
                                    </div>
                                    <div class='documentsCardOne d-flex  justify-content-center align-items-center mt-2'>
                                        <div class='documentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Family & Dependents</span>
                                            <p>H-1B workers' spouses and minor children are regarded as dependents and qualify for H-4 nonimmigrant status. Only when the primary H-1B beneficiary is present in the country in good standing H-1B status are H-4 beneficiaries permitted to be in H-4 status.</p>
                                        </div>
                                    </div>
                                    <div class='documentsCardOne d-flex  justify-content-center align-items-center mt-4'>
                                        <div class='documentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Lottery System</span>
                                            <p>From 1st of April to 1st of October is the actual Fiscal Year for the lottery withdrawal.</p>
                                        </div>
                                    </div>
                                </div> */}
                                <div class='documentTextwraponeSubDivtwo'>
                                    <div class='documentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='documentsRequiredTextDiv d-flex flex-column align-items-md-start align-items-start' style={{ 'textAlign': 'left' }}>
                                            <span>Documents Required</span>
                                            <p>•	Proof of Funds</p>
                                            <p>•	Official Passport</p>
                                            <p>•	Education Credential Assessment (ECA) </p>
                                            <p>•	Scores from a language test</p>
                                            <p>•	A letter of acceptance from a Canadian company</p>
                                            <p>•	Territorial certificates of nomination or provincial certificates of nomination</p>
                                            <p>•	Marriage licenses or a divorce decree (if applicable)</p>
                                            <p>•	Personal reference number for you</p>
                                            <p>•	Express entry profile number</p>
                                            <p>•	Validation code for job seekers</p>
                                            <p>•	Healthcare report</p>
                                            <p>•	Birth certificate documents</p>
                                            <p>•	Verification report from the police</p>
                                            <p>•	Letters of support from former employers</p>
                                            <span className='paperwork'>The paperwork needed as evidence of funds includes</span>
                                            <p>•	Statements of bank accounts</p>
                                            <p>•	Bonds, debentures, and shares</p>
                                            <p>•	Credit and loan agreements</p>
                                            <p>•	The previous salary and compensation information</p>
                                            <p>•	The asset or property valuation</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='documentTextwrapTwoDiv'>
                                <img class='formImgSize' src={paperwork} alt='not viewed' />
                            </div>
                        </div>
                    </div>
                </div>
                <div class='contactusMainDiv d-flex flex-column align-items-center' id='contactdiv'>
                    <div class='contactusTextDiv d-flex justify-content-center align-items-center'>
                        <span>Contact Us</span>
                    </div>
                    <div class='contactusCardsMainDiv'>
                        <div class='row'>
                            <div class='col-6 col-md-4 d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={callanytime} alt='not visible' />
                                        <span class='callAnytimeText'>Call any time</span>
                                        <p class='callAnytimeparagraph'>For immediate response reach out us at .</p>
                                        {/* <a><HiPhone color='#0896CC' /><span style={{ color: '#0896cc' }}>US : +1(570) 521-1612</span></a> */}
                                        <a href="https://wa.link/sqsle0" target="_blank"><BsWhatsapp color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        <a href="tel:9963525491" target="_blank"><HiPhone color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        {/* <a href='https://wa.link/drdbtk' target='blank'><BsWhatsapp color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9849374565</span></a> */}
                                    </div>
                                </div>
                            </div>
                            <div class='col-6 col-md-4 columnscheckdiv d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={emailus} alt='not visible' />
                                        <span class='callAnytimeText'>Email Us</span>
                                        <p class='callAnytimeparagraph'>Ask us a question by email and we will respond immediately.</p>
                                        <a href='mailto:info@lokah.co.in'>info@lokah.co.in</a>
                                        <a href='mailto:hrinfo@lokah.co.in'>hrinfo@lokah.co.in</a>

                                    </div>
                                </div>

                            </div>
                            <div class='col-12 col-md-4 columnscheckdiv d-flex justify-content-center  '>
                                <div class='contactusCardTwoDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start '>
                                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank"> <img class='callanytime' src={officeaddress} alt='not visible' /></a>
                                        <span class='callAnytimeText'>Office Address</span>
                                        <p class='callAnytimeparagraph mt-1'>Visana Residency, Flat No. 503,</p>
                                        <p class='callAnytimeparagraph'> Krishna Sai Enclave Near Maktha,</p>
                                        <p class='callAnytimeparagraph'> JP Nagar, Miyapur,</p>
                                        <p class='callAnytimeparagraph'> Hyderabad,500049.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class='contactusSubDiv d-flex flex-column-reverse flex-md-row'>
                        <div class='contactusGirlmainDiv'>

                        </div>

                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank" class='contactusMapmainDiv'>

                        </a>

                    </div>
                </div>

                {/* Faq block */}

                <div className='documentsBlocl'>

                    <div className='questionsBlock'>
                        <h2 className=''>Frequently Asked Questions</h2>

                        <div className="tab">

                            <ul className='H1B'>
                                <a href="#link1"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How do I apply for Canada PR?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link1">
                                        <p>When you initially arrive in Canada, Canadian border agents fill out the necessary paperwork as part of the process. There is nothing else you need to do. Your first PR card typically takes three to four months to arrive in the mail. For the most recent information on deadlines, visit the government website's list of processing times. </p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>


                    <div className='questionsBlock'>

                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link2"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What is a PR Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link2">
                                        <p>An individual who has been granted permanent resident status (PR) but is not a Canadian citizen is known as a permanent resident (PR). Foreign nationals are given a PR visa in their passport when they initially apply for and are granted permanent residency. It has a validity period within which people are required to go to Canada and finish their landing formalities. It grants approved people a single admission to Canada.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>

                        <div className=" tab">
                            <ul className='H1B'  >
                                <a href="#link3"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}> How to immigrate to Canada?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link3">
                                        <p>Newcomers can immigrate to Canada in a variety of ways. As indicated above, there are several immigration programs available that result in permanent status.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link4">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How to get Canada PR for whole family? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link4">
                                        <p>If your family members are approved for PR as your dependents, they might be allowed to immigrate to Canada with you. This comprises:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Common-law partner or spouse</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Relying child </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Dependent child of your husband or common-law partner</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} /> A dependent relative of another dependent</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link5">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How do I get Canadian Citizenship?  </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link5">
                                        <p>If you want to become a citizen of Canada, you need to:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Becoming a resident permanently</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	For at least three years, you've filed your taxes.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Clear the citizenship test</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Show off your French or English linguistic proficiency.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Not possess a criminal history</p>



                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link6">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What is Express Entry Program? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link6">
                                        <p>One of the most popular and sought-after routes to Canadian immigration is the Express Entry (EE) program. Through the online immigration application process known as Express Entry, potential immigrants can be given the status of skilled immigrants.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link7">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How do I create an Express Entry Program? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link7">
                                        <p>You must first register for an account with Immigration, Refugees and Citizenship Canada (IRCC) to construct an Express Entry profile, and you must then submit your profile to formally join the Express Entry pool of applicants. What you'll need to build your profile is:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} /> Number in the National Occupational Classification (NOC): The Canadian government utilizes this approach to categorize different jobs and occupations.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />Assessment of educational credentials (ECA): This report attests to the validity and parity of your international degree, diploma, certificate, or other credentials with Canadian equivalents. </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />Test results for this approved language are available in either English or French. Your proficiency in talking, reading, hearing, and writing is assessed by this test.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link8">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How to calculate points for Express Entry Program? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link8">
                                        <p>Your Comprehensive Ranking System (CRS) score can be calculated using a tool available online from the Canadian government. Canadian immigration evaluates and ranks your character in the Express Entry pool using the points-based CRS. Among the elements the tool evaluates are: </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Qualifications </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Education </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Language </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Experience</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How can I work in Canada?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>For them to be able to work in Canada, foreign nationals will need a work permit. You might occasionally be able to work in Canada without a work permit, but. </p>
                                        <p>Work permits come in two varieties:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Open work permit: You can work for any employer in Canada with an open work permit, but you can only obtain one in certain circumstances.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Employer-specific work permit: Also known as a closed work permit, this document outlines the name, address, and hours you are permitted to work.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                </div>

            </div >
            <Footer />
        </>
    )
}

export default CanadaPR