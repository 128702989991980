import React from 'react'
import './H1BTransfer.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import paperwork from '../../Assets/paperwork.png'
import map from '../../Assets/map.png'
import h1btmale from '../../Assets/h1btmale.png'
import callanytime from '../../Assets/callanytime.png'
import emailus from '../../Assets/emailus.png'
import officeaddress from '../../Assets/officeaddress.png'
import { HiPhone } from 'react-icons/hi'
import { BsWhatsapp } from 'react-icons/bs'
import { RiArrowDropDownFill } from 'react-icons/ri';
import { GoPrimitiveDot } from 'react-icons/go';

const H1BTransfer = () => {
    return (
        <>
            <Header />
            <div class='H1BTmainDiv d-flex flex-column align-items-center'>
                <div class='MainDivH1BT d-flex justify-content-center'>
                    <div class='H1BTextimgtransDiv d-flex flex-column flex-md-row justify-content-center justify-content-md-around align-items-center H1BTTexteTransimg'>
                        <p class='H1BTtextOne'>H1B Transfer</p>
                        {/* <div class="H1BTimgtransDiv">

                        </div> */}
                    </div>
                </div>

                <div class='H1BTTextSecondDiv d-flex flex-column flex-md-row justify-content-center'>
                    <div class='H1BTTextOneDiv d-flex-justify-conent-center'>
                        <div class='H1BTTextOneSubDiv d-flex justify-content-center justify-content-md-start'>
                            <h1>H1B Transfer</h1>
                        </div>
                        <p>The H1B transfer procedure is comparable to the initial H1B visa application process. The absence of a visa cap is one distinction between the first H1B visa and the H1B visa transfer. A maximum of 65,000 people can obtain an H1B visa each year. But there is no visa cap for the H1B transfer. You do not participate in the H1B lottery if you already have an H1B visa as you can transfer it using the H-1B transfer option.</p>
                        <p>  Holders of H1B visas are permitted to switch employers while still residing in the US through a procedure called an H1B transfer. If you still have a valid H1B visa, you must accept the new employment offer before you may apply for an H1B transfer. The H1B transfer status can then be started, which could take a few months.
                            To start the H1B transfer visa process if you are currently employed by company A, you will require a job offer from company B in the US. If you haven't received an offer of employment, you cannot transfer to some other employer.

                        </p>

                    </div>
                    <div class='H1BTImageTwoDiv d-flex align-items-center justify-content-center'>
                        <img class='H1BTformImgSize' src={h1btmale} alt='notvisible' />
                    </div>
                </div>

                <div class='H1BTDocumentsRequired d-flex flex-column align-items-center'>
                    <div class='d-flex justify-content-center  mt-3'>
                        <h2 class='H1BTDocReqHead'>Documents Required</h2>
                    </div>
                    <div class='H1BTdocumentsDataMainDIv d-flex flex-column-reverse flex-md-column align-items-center'>
                        <div class='H1BTDocumentReqMabdateDiv'>

                            <p>The H1B petition's I-129 form is used to request permission from the company to hire migrant employees. The United States Citizenship and Immigration Service
                                receives this form. USCIS examines the application and either accepts or rejects the petition. USCIS sends the employee and employer a receipt number while
                                processing it. The individual can start working for the new employer as soon as they acquire this receipt number. Each party gets an I-797 form after the petition
                                is authorized. This document attests to the employee's legal right to work for that business in the US. The Employment Eligibility Verification form, often known as
                                Form I-9, must also be submitted by the employer to USCIS. Other than that, the rest of the documents that are required for the H1B transfer are the same as H1B Filing.</p>
                        </div>
                        <div class='H1BTdocumentTextMAinwarp d-flex flex-column flex-md-row justify-content-center align-items-center mt-4'>
                            <div class='H1BTdocumentTextwraponeDiv d-flex flex-column flex-md-row gap-2'>
                                <div class='H1BTdocumentTextwraponeSubDivone d-flex flex-column align-items-start'>
                                    <div class='H1BTdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='H1BTdocumentsCardSubdiv d-flex flex-column  align-items-start'>
                                            <span>Additional Documents would include:</span>
                                            <p>•	Resume</p>
                                            <p>•	U.S. visa, I-797, and I-94 </p>
                                            <p>•	Paystubs, an employer's letter, or a letter requesting a notice of absence.</p>
                                            <p>•	College degree</p>
                                            <p>•	Academic Evaluation on a university transcript</p>
                                        </div>
                                    </div>
                                    <div class='H1BTdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                        <div class='H1BTdocumentsCardSubdiv d-flex flex-column  align-items-start'>
                                            <span>Documents from a U.S. Employer for a Transfer:</span>
                                            <p>•	A letter of employment that is signed by the employer and the visa holder and includes the position's title and pay.</p>
                                            <p>•	A thorough breakdown of the obligations and responsibilities of the role</p>
                                            <p>•	promotional materials from the business</p>
                                            <p>•	Annual reports, financial statements, or business plans</p>
                                        </div>
                                    </div>
                                    {/* <div class='H1BTdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                        <div class='H1BTdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Lottery System</span>
                                            <p>From 1st of April to 1st of October is the actual Fiscal Year for the lottery withdrawal.</p>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div class='H1BTdocumentTextwraponeSubDivtwo'>
                                    <div class='H1BTdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='H1BTdocumentsRequiredTextDiv d-flex flex-column align-items-md-start align-items-start' style={{ 'textAlign': 'left' }}>
                                            <span>Documents Required</span>
                                            <p>•	Passport</p>
                                            <p>•	Labor Certificate Approval</p>
                                            <p>•	Appointment Letter offered by your employer</p>
                                            <p>•	Tax returns paper of the company</p>
                                            <p>•	Copy of the letter sent to the Department of Justice </p>
                                            <p> •	Copy of the letter sent to the Consular General of the Indian Consulate</p>
                                            <p>•	Copy of the official valuation of your degrees</p>
                                            <p>•	UG certificate</p>
                                            <p>•	Appointment and Relieving Certificates from the previous companies that you were employed at.</p>
                                            <p>•	Dates of prior stay in U.S. in H1-B status</p>
                                            <p>•	Job description and duty with the sponsoring U.S. Company to be described in short.</p>
                                            <p>•	Copy of current U.S. License or temporary license</p>
                                            <p>•	Copy of resume with supporting certificates</p>
                                            <p>•	Two demand drafts, $45 for processing fee and $100 for issuance fee</p>
                                            <p>•	2 to 3 passport sized colored photographs</p>

                                            <p>•	If H-4 visa is being applied for dependent members, then you must submit copies of children's birth certificates and copy of marriage certificate.</p>


                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div class='H1BTdocumentTextwrapTwoDiv'>
                                <img class='H1BTformImgSize' src={paperwork} alt='not viewed' />
                            </div>
                        </div>
                    </div>
                </div>

                <div class='contactusMainDiv d-flex flex-column align-items-center' id='contactdiv'>
                    <div class='contactusTextDiv d-flex justify-content-center align-items-center'>
                        <span>Contact Us</span>
                    </div>
                    <div class='contactusCardsMainDiv'>
                        <div class='row'>
                            <div class='col-6 col-md-4 d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={callanytime} alt='not visible' />
                                        <span class='callAnytimeText'>Call any time</span>
                                        <p class='callAnytimeparagraph'>For immediate response reach out us at .</p>
                                        {/* <a><HiPhone color='#0896CC' /><span style={{ color: '#0896cc' }}>US : +1(570) 521-1612</span></a> */}
                                        <a href="https://wa.link/sqsle0" target="_blank"><BsWhatsapp color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        <a href="tel:9963525491"  target="_blank"><HiPhone color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        {/* <a href='https://wa.link/drdbtk' target='blank'><BsWhatsapp color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9849374565</span></a> */}
                                    </div>
                                </div>
                            </div>
                            <div class='col-6 col-md-4 columnscheckdiv d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={emailus} alt='not visible' />
                                        <span class='callAnytimeText'>Email Us</span>
                                        <p class='callAnytimeparagraph'>Ask us a question by email and we will respond immediately.</p>
                                        <a href='mailto:info@lokah.co.in'>info@lokah.co.in</a>
                                        <a href='mailto:hrinfo@lokah.co.in'>hrinfo@lokah.co.in</a>

                                    </div>
                                </div>

                            </div>
                            <div class='col-12 col-md-4 columnscheckdiv d-flex justify-content-center  '>
                                <div class='contactusCardTwoDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start '>
                                    <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank"> <img class='callanytime' src={officeaddress} alt='not visible' /></a> 
                                        <span class='callAnytimeText'>Office Address</span>
                                        <p class='callAnytimeparagraph '>Visana Residency, Flat No. 503,</p>
                                        <p class='callAnytimeparagraph'> Krishna Sai Enclave Near Maktha,</p>
                                        <p class='callAnytimeparagraph'> JP Nagar, Miyapur,</p>
                                        <p class='callAnytimeparagraph'> Hyderabad,500049.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class='contactusSubDiv d-flex flex-column-reverse flex-md-row'>
                        <div class='contactusGirlmainDiv'>

                        </div>
                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank" class='contactusMapmainDiv'>

</a>
                    </div>
                </div>


                {/* Faq block */}

                <div className='documentsBlocl'>

                    <div className='questionsBlock'>
                        <h2 className=''>Frequently Asked Questions</h2>

                        <div className="tab">

                            <ul className='H1B'>
                                <a href="#link1"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Does the standard H-1B cap get any bearing on how I transfer my H-1B status?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link1">
                                        <p>The ordinary H-1B cap is a distinct procedure unto itself. The good news is that the standard quote won't affect your chances of getting the clearance. USCIS won't place your H-1B transfer application into the lottery since you are already deducted from the cap. </p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>


                    <div className='questionsBlock'>

                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link2"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Is there a limit to how many times I can submit a transfer application?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link2">
                                        <p>There are no restrictions on how frequently you can apply for a transfer. One of the many advantages of an H-1B visa over the other work visas, such as the L-1 visa, is portability.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link4">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Can I submit more than one H-1B transfer application at once?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link4">
                                        <p>Yes, there is nothing stopping you from submitting applications to various employers. You can work part-time and for numerous employers at once if you have an H-1B visa.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link5">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Do I have to notify my current employer before submitting an H-1B transfer application with a new company?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link5">
                                        <p>An H-1B move does not require that you inform your present employer. What information you choose to share with coworkers and employers is entirely up to you.</p>



                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link6">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>If I simply have an H-1B transfer receipt and the petition has not yet been authorised, may I begin working for my employer?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link6">
                                        <p>Yes. You may start working at your new H-1B employment before getting clearance as long as certain conditions are satisfied and you have a confirmation that the USCIS has received your petition. The following criteria must be satisfied:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />Never worked in a capacity that wasn't authorised by you</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />Already possess a current H-1B status</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />You entered the country on legal grounds.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />If you can check off all these requirements, USCIS will probably approve your H-1B transfer petition, allowing you to begin working for your new employer. </p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link7">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What are some important things to remember about H-1B transfer?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link7">
                                        <p>A.	If you can check off all these requirements, USCIS will certainly accept your H-1B transfer petition, allowing you to begin working for your new employer. Remember that a confirmation from your delivery person will suffice; you don't absolutely need one from the USCIS.
                                            If the USCIS rejects your transfer petition, you must stop working right once and make travel arrangements to leave the country or locate a new H-1B position before your 60-day waiting period runs out. Therefore, before receiving the official clearance notification, you must weigh the danger of operating for the new business.
                                        </p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link8">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Will I need to obtain a new H-1B visa stamp in my passport if I already have an H-1B visa stamp from my prior company but now have obtained a fresh approval (new employer)? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link8">
                                        <p>There is a distinction between possessing an H-1B visa stamp and having H-1B status. While USCIS requires H-1B status for you to serve for your petitioning employer, the visa stamp permits your admission into the country. Let's say your current H-1B employer has received permission, in which case you can only work legally for that company. However, you can utilize your H-1B stamp in tandem with your fresh petition to enter the country if you leave the country while it is still valid. </p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What occurs if your H-1B visa stamp is no longer valid?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>Before returning to the United States, you must obtain another passport stamp. You may benefit more from the advice of an H-1B transfer lawyer in this kind of circumstance.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Is there a streamlined application process for the H-1B visa transfer?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>This inquiry comes up frequently because many people believe that transferring an H-1B visa is a simple and quick process. Sadly, it's not quite that easy. Each file must be thorough and show that the applicant meets the requirements for that category.
                                            You run the risk of having your application denied by USCIS if the required compliance standards are not met or the supporting documentation is incomplete. You should see a knowledgeable H-1B transfer lawyer as a result.
                                        </p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What happens if my application for an H-1B transfer to a different employer is accepted but I ultimately decide to stick with my current company?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>You can continue working for your present employer even after USCIS confirms your H-1B transfer.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <Footer />
        </>
    )
}

export default H1BTransfer