import React from 'react'
import './H1BFilling.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import womenform from '../../Assets/Womenformimg.png'
import paperwork from '../../Assets/paperwork.png'
import tickicon from '../../Assets/ticicon.png'
import map from '../../Assets/map.png'
import callanytime from '../../Assets/callanytime.png'
import emailus from '../../Assets/emailus.png'
import officeaddress from '../../Assets/officeaddress.png'
import { HiPhone } from 'react-icons/hi'
import { BsWhatsapp } from 'react-icons/bs'
import { RiArrowDropDownFill } from 'react-icons/ri';
import { GoPrimitiveDot } from 'react-icons/go';
const H1Bfilling = () => {
    return (
        <>
            <Header />
            <div class='H1BFmainDiv d-flex flex-column align-items-center'>
                <div class='MainDivH1BF d-flex justify-content-center'>
                    <div class='H1BFTextimgtransDiv d-flex flex-column flex-md-row justify-content-center justify-content-md-around align-items-center H1BFTexteTransimg'>
                        <p class='H1BFtextOne'>Fresh H1B Filing</p>
                        {/* <div class="H1BFimgtransDiv">

                        </div> */}
                    </div>
                </div>

                <div class='H1BFTextSecondDiv d-flex flex-column flex-md-row justify-content-center'>
                    <div class='H1BFTextOneDiv d-flex-justify-conent-center'>
                        <div class='H1BFTextOneSubDiv d-flex justify-content-center justify-content-md-start'>
                            <h1>Fresh H1B Filing</h1>
                        </div>
                        <p>The H1B nonimmigrant is permitted a 3-year stay in the United States, which can be extended, but not for longer than a total of 6-years. The American Competitiveness in the Twenty-First Century Act includes for the extending of the stay period. For those whose petitions for permanent residency are still pending, there is also a restricted extension. We help you do your H-1B filing with an ease.
                            Noncitizens who want to work in a specialty occupation, provide extraordinary merit and ability in connection with a Department of Defense (DOD) joint research and development project, or provide distinguishing merit or skill as a fashion model can apply for an H1B visa.
                        </p>
                        <p> The following criteria must be satisfied for an application to be granted an H1B visa for speciality occupations:
                        </p>
                        <p>•	A body of highly specialized knowledge used both theoretically and practically; a Bachelor’s or higher degree in the relevant specialty (or its equivalent).</p>
                        <p>•	Additionally, one of the preceding prerequisites must be satisfied by the position:
                            Bachelor's degree or higher, or its equivalent, as a prerequisite,
                        </p>
                        <p>•	The industry standard degree requirement, or the work is so complex or special that only someone with a degree can accomplish it.</p>
                        <p>•	The nature of the specific activities is so specialised and difficult that the knowledge necessary to fulfil the duties with a Bachelor's degree. The employer often necessitates a degree or similar for the position.</p>
                    </div>
                    <div class='H1BFImageTwoDiv d-flex align-items-center justify-content-center'>
                        <img class='H1BFformImgSize' src={womenform} alt='notvisible' />
                    </div>
                </div>

                <div class='H1BFDocumentsRequired d-flex flex-column align-items-center'>
                    <div class='d-flex justify-content-center  mt-3'>
                        <h2 class='H1BFDocReqHead'>Documents Required</h2>
                    </div>
                    <div class='H1BFdocumentsDataMainDIv d-flex flex-column-reverse flex-md-column align-items-center'>
                        <div class='H1BFDocumentReqMabdateDiv'>
                            <div class='d-flex  justify-content-start gap-2'>
                                <div class='d-flex flex-column align-items-start justify-content-between'>
                                    <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                    <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                    <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                </div>
                                <div class='d-flex flex-column align-items-start gap-2 Mandate'>
                                    <span class='mandateText'> Mandate bachelor degree</span>
                                    <span class='mandateText'>  Relevant licence required</span>
                                    <span class='mandateText'>  Relevant experience if required</span>
                                </div>
                            </div>
                        </div>
                        <div class='H1BFdocumentTextMAinwarp d-flex flex-column flex-md-row justify-content-center align-items-center mt-4'>
                            <div class='H1BFdocumentTextwraponeDiv d-flex flex-column flex-md-row gap-2'>
                                <div class='H1BFdocumentTextwraponeSubDivone d-flex flex-column align-items-start'>
                                    <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='H1BFdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Duration of Stay</span>
                                            <p>Three Years, Extendable to Six Years</p>
                                        </div>
                                    </div>
                                    <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                        <div class='H1BFdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Family & Dependents</span>
                                            <p>H-1B workers' spouses and minor children are regarded as dependents and qualify for H-4 nonimmigrant status. Only when the primary H-1B beneficiary is present in the country in good standing H-1B status are H-4 beneficiaries permitted to be in H-4 status.</p>
                                        </div>
                                    </div>
                                    <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                        <div class='H1BFdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                            <span>Lottery System</span>
                                            <p>From 1st of April to 1st of October is the actual Fiscal Year for the lottery withdrawal.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class='H1BFdocumentTextwraponeSubDivtwo'>
                                    <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='H1BFdocumentsRequiredTextDiv d-flex flex-column align-items-md-start align-items-start' style={{ 'textAlign': 'left' }}>
                                            <span>Documents Required</span>
                                            <p>•	Passport</p>
                                            <p>•	Labor Certificate Approval</p>
                                            <p>•	Appointment Letter offered by your employer</p>
                                            <p>•	Tax returns paper of the company</p>
                                            <p>•	Copy of the letter sent to the Department of Justice </p>
                                            <p> •	Copy of the letter sent to the Consular General of the Indian Consulate</p>
                                            <p>•	Copy of the official valuation of your degrees</p>
                                            <p>•	UG certificate</p>
                                            <p>•	Appointment and Relieving Certificates from the previous companies that you were employed at.</p>
                                            <p>•	Dates of prior stay in U.S. in H1-B status</p>
                                            <p>•	Job description and duty with the sponsoring U.S. Company to be described in short.</p>
                                            <p>•	Copy of current U.S. License or temporary license</p>
                                            <p>•	Copy of resume with supporting certificates</p>
                                            <p>•	Two demand drafts, $45 for processing fee and $100 for issuance fee</p>
                                            <p>•	2 to 3 passport sized colored photographs</p>

                                            <p>•	If H-4 visa is being applied for dependent members, then you must submit copies of children's birth certificates and copy of marriage certificate.</p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='H1BFdocumentTextwrapTwoDiv'>
                                <img class='H1BFformImgSize' src={paperwork} alt='not viewed' />
                            </div>
                        </div>
                    </div>
                </div>

                <div class='contactusMainDiv d-flex flex-column align-items-center' id='contactdiv'>
                    <div class='contactusTextDiv d-flex justify-content-center align-items-center'>
                        <span>Contact Us</span>
                    </div>
                    <div class='contactusCardsMainDiv'>
                        <div class='row'>
                            <div class='col-6 col-md-4 d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={callanytime} alt='not visible' />
                                        <span class='callAnytimeText'>Call any time</span>
                                        <p class='callAnytimeparagraph'>For immediate response reach out us at .</p>
                                        {/* <a href='tel:'><HiPhone color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>US : +1(570) 521-1612</span></a> */}
                                        <a href="https://wa.link/sqsle0" target="_blank"><BsWhatsapp color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        <a href="tel:9963525491" target="_blank"><HiPhone color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        {/* <a href='https://wa.link/drdbtk' target='blank'><BsWhatsapp color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9849374565</span></a> */}
                                    </div>
                                </div>
                            </div>
                            <div class='col-6 col-md-4 columnscheckdiv d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={emailus} alt='not visible' />
                                        <span class='callAnytimeText'>Email Us</span>
                                        <p class='callAnytimeparagraph'>Ask us a question by email and we will respond immediately.</p>
                                        <a href='mailto:info@lokah.co.in'>info@lokah.co.in</a>
                                        <a href='mailto:hrinfo@lokah.co.in'>hrinfo@lokah.co.in</a>

                                    </div>
                                </div>

                            </div>
                            <div class='col-12 col-md-4 columnscheckdiv d-flex justify-content-center  '>
                                <div class='contactusCardTwoDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start '>
                                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank"> <img class='callanytime' src={officeaddress} alt='not visible' /></a>
                                        <span class='callAnytimeText'>Office Address</span>
                                        <p class='callAnytimeparagraph mt-1'>Visana Residency, Flat No. 503,</p>
                                        <p class='callAnytimeparagraph'> Krishna Sai Enclave Near Maktha,</p>
                                        <p class='callAnytimeparagraph'> JP Nagar, Miyapur,</p>
                                        <p class='callAnytimeparagraph'> Hyderabad,500049.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class='contactusSubDiv d-flex flex-column-reverse flex-md-row'>
                        <div class='contactusGirlmainDiv'>

                        </div>
                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank" class='contactusMapmainDiv'>

                        </a>
                    </div>
                </div>


                {/* Faq block */}

                <div className='documentsBlocl'>

                    <div className='questionsBlock'>
                        <h2 className=''>Frequently Asked Questions</h2>

                        <div className="tab">

                            <ul className='H1B'>
                                <a href="#link1"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What is H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link1">
                                        <p>A Person in Specialty Occupation Visa is another name for the H1B visa. It means that if a US Employer accepts you for a particular job post, you are eligible for the H1B visa. </p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>


                    <div className='questionsBlock'>

                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link2"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Who initiates H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link2">
                                        <p>An employer in the United States requests the H1B work visa. The business must have an open position and be unable to locate an American worker who is capable of finishing the work. Any position that needs advanced degrees or is sufficiently specialized in skills that few people can perform it successfully falls under this category.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link4">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Who can apply for H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link4">

                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Holding a degree from a prestigious university, such as a four-year bachelor's (or equivalent degrees)</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	A doctoral or master's degree</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Advanced education or practical experience </p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} /> Meet the requirements to work on US Department of Defense research and development programs or in other government jobs.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link5">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How to apply for H-1B Visa? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link5">
                                        <p>The actions you must do in order to apply for an H1B visa are listed below:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Make sure you are H1B visa eligible.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	By submitting an application for a position in the US, you can locate a petitioner.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Have your employer be the one to start the petition.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Visit the closest US Embassy or Consulate in your nation to submit an application for an H1B visa.</p>



                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link6">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How to file an H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link6">
                                        <p>The procedures that employers must follow in order to petition a foreign worker are as follows:</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	An application for labor conditions (LCA).</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Petition to USCIS</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Finish the I-129 form.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Send in the paperwork and the forms.</p>
                                        <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Attend to the USCIS review.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link7">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What is H-1B Lottery?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link7">
                                        <p>In March, your employer has two weeks to file an H1B petition with the USCIS. (The H1B lottery for 2021 began on March 9 and ran through March 25.) Following submission, the USCIS processed the petition using a digital random selection technique to choose 85,000 individuals at random.</p>
                                        <p>The US grants 20,000 H1B visas to candidates with master's degrees or higher and 65,000 standard H1B visas.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link8">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How long do I stay in the US with H-1B Visa? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link8">
                                        <p>The H1B visa has a three-year initial validity period and a three-year extension period. A person may only reside and work in the United States for a total of six years. The individual need not, however, go back to their nation of origin. The fact that the H1B visa has two purposes is what makes it so well-liked. </p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How to extend the H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>The processes for H1B extensions are the same as those for initial applications. With the exception of the Fraud Prevention and Protection Fee, you will be required to pay the same fees. The same steps must be taken by your company in order to obtain DOL certification and a USCIS-approved petition.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Can I bring my partner with me through H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>Many people with H1B visas choose to travel with their partners and kids. If they are granted a H 4 visa, spouses are allowed to travel with H1B visa holders. Since 2015, individuals with H-4 visas may be capable of working under the H4 EAD program under specific circumstances. Additionally, persons with H-4 visas are permitted to enroll in academic programs.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How to transfer my H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>You can request an H1-B transfer to another employer. However, you must have a job that is legal in the US. Additionally, the most recent W2 forms and pay statements are required.</p>
                                        <p>If you don't have current pay stubs, you'll need to give USCIS an explanation for why you don't have them. It might be related to extended unpaid sick leave or vacation. It is necessary to produce the letter of approval for the same. Even if you hold a current H1-B Visa or an active I-94 card, your H1-B status will be lost if you lose your work.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <Footer />
        </>
    )
}

export default H1Bfilling