import React from 'react'
import './UKWork.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import paperwork from '../../Assets/paperwork.png'
import ukworkshow from '../../Assets/ukworkshow.png'
import callanytime from '../../Assets/callanytime.png'
import emailus from '../../Assets/emailus.png'
import officeaddress from '../../Assets/officeaddress.png'
import { HiPhone } from 'react-icons/hi'
import { BsWhatsapp } from 'react-icons/bs'
import { RiArrowDropDownFill } from 'react-icons/ri';
import map from '../../Assets/map.png'

const UKWork = () => {
    return (
        <>
            <Header />
            <div class='UKWmainDiv d-flex flex-column align-items-center'>
                <div class='MainDivUKW d-flex justify-content-center'>
                    <div class='UKWextimgtransDiv d-flex flex-column flex-md-row justify-content-center justify-content-md-around align-items-center UKWTexteTransimg'>
                        <p class='UKWtextOne'>UK Work</p>
                        {/* <div class="UKWimgtransDiv">

                        </div> */}
                    </div>
                </div>

                <div class='UKWTextSecondDiv d-flex flex-column flex-md-row justify-content-center'>
                    <div class='UKWTextOneDiv d-flex-justify-conent-center'>
                        <div class='UKWTextOneSubDiv d-flex justify-content-center justify-content-md-start'>
                            <h1>UK Work</h1>
                        </div>
                        <p>The UK invites skilled individuals to work in the UK through the Tier 2 visa scheme to maintain its competitive edge. Workers whose professions are on the Tier 2 Shortage Occupations List are eligible to apply to work in the UK permanently via this program. The list includes popular occupations like IT, finance, teaching, healthcare, and engineering. Y-Axis can assist you in positioning yourself to benefit from the UK's skills deficit and secure a work permit for the country.</p>
                        <p>The Skilled Worker visa is required for skilled workers who must travel to the UK (formerly the Tier 2 visa). If a skilled employment in the UK has been offered to you, you may apply for this visa.
                            Professionals with the necessary skills can establish themselves in the UK with the help of the skilled worker visa. According to the U.K. Skilled Worker, the stay can last up to 5 years. A minimum of 70 points must be earned for a candidate's application to be taken into consideration for the points-based Skilled Worker Visa.
                        </p>
                        <p>The following criteria are used to determine the points:</p>
                        <p>•	If possess a Certificate of Sponsorship from a company</p>
                        <p>•	Whether you are making a sufficient wage</p>
                        <p>•	Your ability to communicate in English</p>
                        <p>•	You have maintenance funds.</p>
                        <p>•	You can then apply for the Skilled Worker Visa if you meet these requirements.</p>

                    </div>
                    <div class='UKWImageTwoDiv d-flex align-items-center justify-content-center'>
                        <img class='UKWformImgSize' src={ukworkshow} alt='notvisible' />
                    </div>
                </div>

                <div class='UKWDocumentsRequired d-flex flex-column align-items-center'>
                    <div class='d-flex justify-content-center  mt-3'>
                        <h2 class='UKWDocReqHead'>Documents Required</h2>
                    </div>
                    <div class='UKWdocumentsDataMainDIv d-flex flex-column-reverse flex-md-column align-items-center'>
                        {/* <div class='UKWDocumentReqMabdateDiv'>

                            <p>The H1B petition's I-129 form is used to request permission from the company to hire migrant employees. The United States Citizenship and Immigration Service
                                receives this form. USCIS examines the application and either accepts or rejects the petition. USCIS sends the employee and employer a receipt number while
                                processing it. The individual can start working for the new employer as soon as they acquire this receipt number. Each party gets an I-797 form after the petition
                                is authorized. This document attests to the employee's legal right to work for that business in the US. The Employment Eligibility Verification form, often known as
                                Form I-9, must also be submitted by the employer to USCIS. Other than that, the rest of the documents that are required for the H1B transfer are the same as H1B Filing.</p>
                        </div> */}
                        <div class='UKWdocumentTextMAinwarp d-flex flex-column flex-md-row justify-content-center align-items-center mt-4'>
                            <div class='UKWdocumentTextwraponeDiv d-flex flex-column flex-md-row gap-2'>
                                <div class='UKWdocumentTextwraponeSubDivone d-flex flex-column align-items-start'>
                                    <div class='UKWdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                        <div class='UKWdocumentsCardSubdiv d-flex flex-column  align-items-start'>
                                            <span>When you apply, you must submit:</span>
                                            <p>•	Your employer will provide you with a reference number for your sponsorship certificate.</p>
                                            <p>•	Proof of your English language proficiency</p>
                                            <p>•	A current passport or other proof of identity and nationality</p>
                                            <p>•	Your name, position, and yearly salary</p>
                                            <p>•	The occupation code for your work</p>
                                            <p>•	This information will be on your certification of sponsorship, along with your employer's name and sponsor license number.</p>
                                            <p>•	If you don't have a certificate of sponsorship, ask your company for one.</p>
                                        </div>
                                    </div>
                                    <div class='UKWdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                        <div class='UKWdocumentsCardSubdiv d-flex flex-column  align-items-start'>
                                            <span>In light of your particular situation, you might be required to provide:</span>
                                            <p>•	Bank statements or other documentation that demonstrates you have the personal savings necessary to maintain yourself in the UK (unless your certificate of sponsorship shows your employer can support you).</p>
                                            <p>•	If you are applying with your partner or children, documentation of your relationship with them</p>
                                            <p>•	Your tuberculosis test outcomes if you come from one of the nations listed.</p>
                                            <p>•	A certificate of no criminal history if you're working in specified professions.</p>
                                            <p>•	A current ATAS certificate if your employer informs you that it's required since your job entails conducting PhD-level or higher research on a sensitive topic.</p>
                                            <p>•	Your unique Ecctis reference number, which replaced your unique UK NARIC reference number if your degree is from outside the UK, or your UK PhD certificate.</p>
                                        </div>
                                    </div>
                                    {/* <div class='H1BTdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                <div class='H1BTdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                    <span>Lottery System</span>
                                    <p>From 1st of April to 1st of October is the actual Fiscal Year for the lottery withdrawal.</p>
                                </div>
                            </div> */}
                                </div>
                                {/* <div class='H1BTdocumentTextwraponeSubDivtwo'>
                            <div class='H1BTdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                <div class='H1BTdocumentsRequiredTextDiv d-flex flex-column align-items-md-start align-items-start' style={{ 'textAlign': 'left' }}>
                                    <span>Documents Required</span>
                                    <p>•	Passport</p>
                                    <p>•	Labor Certificate Approval</p>
                                    <p>•	Appointment Letter offered by your employer</p>
                                    <p>•	Tax returns paper of the company</p>
                                    <p>•	Copy of the letter sent to the Department of Justice </p>
                                    <p> •	Copy of the letter sent to the Consular General of the Indian Consulate</p>
                                    <p>•	Copy of the official valuation of your degrees</p>
                                    <p>•	UG certificate</p>
                                    <p>•	Appointment and Relieving Certificates from the previous companies that you were employed at.</p>
                                    <p>•	Dates of prior stay in U.S. in H1-B status</p>
                                    <p>•	Job description and duty with the sponsoring U.S. Company to be described in short.</p>
                                    <p>•	Copy of current U.S. License or temporary license</p>
                                    <p>•	Copy of resume with supporting certificates</p>
                                    <p>•	Two demand drafts, $45 for processing fee and $100 for issuance fee</p>
                                    <p>•	2 to 3 passport sized colored photographs</p>

                                    <p>•	If H-4 visa is being applied for dependent members, then you must submit copies of children's birth certificates and copy of marriage certificate.</p>


                                </div>
                            </div>
                        </div> */}
                            </div>
                            <div class='UKWdocumentTextwrapTwoDiv'>
                                <img class='UKWformImgSize' src={paperwork} alt='not viewed' />
                            </div>
                        </div>
                    </div>
                </div>

                <div class='contactusMainDiv d-flex flex-column align-items-center' id='contactdiv'>
                    <div class='contactusTextDiv d-flex justify-content-center align-items-center'>
                        <span>Contact Us</span>
                    </div>
                    <div class='contactusCardsMainDiv'>
                        <div class='row'>
                            <div class='col-6 col-md-4 d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={callanytime} alt='not visible' />
                                        <span class='callAnytimeText'>Call any time</span>
                                        <p class='callAnytimeparagraph'>For immediate response reach out us at .</p>
                                        {/* <a><HiPhone color='#0896CC' /><span style={{ color: '#0896cc' }}>US : +1(570) 521-1612</span></a> */}
                                        <a href="https://wa.link/sqsle0" target="_blank"><BsWhatsapp color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        <a href="tel:9963525491" target="_blank"><HiPhone color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                        {/* <a href='https://wa.link/drdbtk' target='blank'><BsWhatsapp color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9849374565</span></a> */}
                                    </div>
                                </div>
                            </div>
                            <div class='col-6 col-md-4 columnscheckdiv d-flex-justify-content-center'>
                                <div class='contactusCardoneDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                        <img class='callanytime' src={emailus} alt='not visible' />
                                        <span class='callAnytimeText'>Email Us</span>
                                        <p class='callAnytimeparagraph'>Ask us a question by email and we will respond immediately.</p>
                                        <a href='mailto:info@lokah.co.in'>info@lokah.co.in</a>
                                        <a href='mailto:hrinfo@lokah.co.in'>hrinfo@lokah.co.in</a>

                                    </div>
                                </div>

                            </div>
                            <div class='col-12 col-md-4 columnscheckdiv d-flex justify-content-center  '>
                                <div class='contactusCardTwoDiv d-flex justify-content-center'>
                                    <div class='contactusCardoneSubDiv d-flex flex-column align-items-start '>
                                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank"> <img class='callanytime' src={officeaddress} alt='not visible' /></a>
                                        <span class='callAnytimeText'>Office Address</span>
                                        <p class='callAnytimeparagraph '>Visana Residency, Flat No. 503,</p>
                                        <p class='callAnytimeparagraph'> Krishna Sai Enclave Near Maktha,</p>
                                        <p class='callAnytimeparagraph'> JP Nagar, Miyapur,</p>
                                        <p class='callAnytimeparagraph'> Hyderabad,500049.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class='contactusSubDiv d-flex flex-column-reverse flex-md-row'>
                        <div class='contactusGirlmainDiv'>

                        </div>
                        <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank" class='contactusMapmainDiv'>

                        </a>
                    </div>
                </div>


                {/* Faq block */}

                <div className='documentsBlocl'>

                    <div className='questionsBlock'>
                        <h2 className=''>Frequently Asked Questions</h2>

                        <div className="tab">

                            <ul className='H1B'>
                                <a href="#link1"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>What distinguishes the Tier 5 Youth Mobility Scheme from a Tier 2 Work Permit?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link1">
                                        <p>An employer-specific work permit known as a Tier 2 UK work permit is created for professionals, executives, or highly trained individuals who contribute to both the UK and their organization. Tier 2 work permits have no age restriction.</p>
                                        <p>Commonwealth nationals between the ages of 17 and 30 are permitted to enter the UK on a Tier 5 Youth Mobility Scheme visa for up to two years.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>


                    <div className='questionsBlock'>

                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link2"  >
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Will I have to leave the UK if I already have a work visa and want to switch employers?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link2">
                                        <p>You may stay in the UK while your new employment visa application is being reviewed, not.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link4">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Can I work as a sole proprietor in the UK while on a work visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link4">
                                        <p>No, you are not permitted to start your own business, establish one, or work for another company as a manager or partner if you are a Tier 2 General immigrant.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B' >
                                <a href="#link5">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Can I change my visa to work permit while staying in the UK?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link5">
                                        <p>Only foreign nationals who fall under specific Tier 2 and Tier 5 categories, as well as a select group of experts, are permitted to change to a work permit from within the UK.</p>



                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link6">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Can I still obtain a work permit if I have another type of visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link6">
                                        <p>According to your abilities and qualifications, current UK visa status, and other factors, you can be qualified for a work permit.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link7">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>How much time do work permits last?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link7">
                                        <p>For any duration between one day and three years, a work permit may be issued. Depending on the type of visa, a further 2 or 3 years of leave might be requested.    </p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link8">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>During the processing of my work permit, is I allowed to work? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link8">
                                        <p>You might be able to work while your work permit application is being processed, depending on your existing immigration status. To discuss the specifics of your work permit application, get in touch with one of our certified experts. </p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Do I need to have a job to submit a work permit application?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>Yes, you must have a job offer before applying for a work permit, and the sponsoring employer must already have one or be in the process of applying for one.</p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Who can issue a certificate of sponsorship for my application for a work visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>Only an employer with a UK address and a current sponsorship license.   </p>

                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>
                    <div className='questionsBlock'>
                        <div className=" tab">
                            <ul className='H1B'>
                                <a href="#link9">
                                    <li ><a className='question' style={{ textDecoration: 'none' }}>Which nationals are subject to a work permit requirement?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                    <div id="link9">
                                        <p>You will need a work permit if you are a non-EEA citizen and do not already possess a valid visa to work in the UK.</p>
                                    </div>
                                </a>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <Footer />
        </>
    )
}

export default UKWork