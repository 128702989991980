// import logo from './logo.svg';
import React from 'react'
import './App.css';
import CanadaPR from './Components/pages/CanadaPR';
import H1Bfilling from './Components/pages/H1BFilling'
import H1BTransfer from './Components/pages/H1BTransfer';
import UKWork from './Components/pages/UKWork';
import Home from './Components/pages/Home'
import {Routes,Route ,BrowserRouter} from 'react-router-dom'
import Watsapp from './Components/Watsapp';
import Telephone from './Components/Telephone';
function App() {
  return (
    <div className="App">      
    
     <BrowserRouter>
     <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/H1Bfiling_page' element={<H1Bfilling/>}/>
      <Route path='/H1Btransfer_page' element={<H1BTransfer/>}/>
      <Route path='/UKWork_page' element={<UKWork/>}/>
      <Route path='/Canadapr_page' element={<CanadaPR/>}/>
     </Routes>
     </BrowserRouter>
      
       
     
    </div>
  );
}

export default App;
