import React from 'react'
import '../Components/Watsapp.css'
import watsapp from '../Assets/whats.png'
function Watsapp() {
  return (
    <div className='watsappmainDiv'>
      <a href="https://wa.link/sqsle0" target="_blank">  <img className='watsappimg' src={watsapp}/> </a>
    </div>
  )
}

export default Watsapp