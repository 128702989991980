import React from 'react'
import mainImg from '../../Assets/homeimg.png'

import meetuplokah from '../../Assets/meetuplokahimg.png'
import { HiOutlineArrowSmRight } from 'react-icons/hi'
import whyLokah from '../../Assets/whyLokah.png'
import testimonial from '../../Assets/testimonial.png'
import mobilesteps from '../../Assets/mobilesteps.png'
import lapysteps from '../../Assets/lapysteps.png'
import { MdArrowBackIosNew } from 'react-icons/md';
import { MdArrowForwardIos } from 'react-icons/md';
import { AiOutlineLine } from 'react-icons/ai';
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Home.css'
import paperwork from '../../Assets/paperwork.png'
import tickicon from '../../Assets/ticicon.png'
import map from '../../Assets/map.png'
import callanytime from '../../Assets/callanytime.png'
import emailus from '../../Assets/emailus.png'
import officeaddress from '../../Assets/officeaddress.png'
import { HiPhone } from 'react-icons/hi'
import { BsWhatsapp } from 'react-icons/bs'
import { RiArrowDropDownFill } from 'react-icons/ri';
import { GoPrimitiveDot } from 'react-icons/go';

const Home = () => {
    return (
        <>
            <Header />

            <div class='lokahhoMainDiv d-flex flex-column align-items-center '>
                <div class='mainbg d-flex justify-content-center'>
                    <div class='lokahhomeSubdiv d-flex flex-column flex-md-row'>
                        <div class='lokahhomefirstDiv  d-flex flex-column align-items-center'>
                            <div class="headingTexts d-flex flex-column align-items-start">
                                <div class='startYourJourney'>
                                    <span>Start your International Journey today with LOKAH</span>
                                </div>
                                <div class='morethanDiv'>
                                    More than 4,50,000 Indians seek H1B Visa, every year.Now It’s your time to do.
                                </div>
                                <div class='lokahDiscription'>
                                    <p>With Lokah, you can</p>
                                    <p>•	Stay abroad for longer periods.</p>
                                    <p> •	Port your status through employers</p>
                                    <p>•	Bring your spouses and dependents</p>
                                    <p>•	Pursue legal permanent residency</p>

                                </div>
                            </div>
                        </div>
                        <div class='lokahfirstImgDiv d-flex flex-column align-items-center'>
                            <img class='mainimg' src={mainImg} alt='not visible' />
                        </div>
                    </div>
                </div>
                <div>
                    <div class='homeAchivementsMainDiv d-flex flex-column align-items-center'>
                        <div class='homeAchivementsSubDiv d-flex flex-column align-items-start'>
                            <div class='achivementsText'>
                                <span>Your Achievement is our Success Story!</span>
                            </div>
                            <div class='MethodologyText'>
                                H1B A path For – The Dream Of US Employment
                            </div>
                            <div class='achivementDescriptionDiv'>
                                With good reason, the H-1B visa has long been the most sought-after employment visa in the enormous US economy. There are a plethora of advantages that set the H-1B apart from other work visa types. It is simple to understand why so many good professionals internationally apply to make use of the avantages of the H-1B visa each year due to its accessibility and 3years lengthy initial duration of stay. It's time for you to evaluate the opportunity.
                            </div>
                            {/* imgblock begin */}
                            <div>
                                <div className='lapysteps'><img src={lapysteps} /></div>
                                <div className='mobilesteps' > <img src={mobilesteps} /></div>

                            </div>
                            {/* imgblock begin */}
                            {/* <div class='d-flex flex-column flex-md-row stepsMainDiv'>
              <div class='stepOneDiv d-flex justify-content-center'>
                <img class='steponeImg' src={stepone} alt='novisible' />
              </div>
              <div class='steptwoDiv  d-flex justify-content-md-start justify-content-center '>
                <img class='steptwoImg' src={steptwo} alt='novisible' />
              </div>

            </div> */}

                        </div>

                        <div class='meetLokahMaindiv d-flex flex-column align-items-center' id='meetlokah'>
                            <div class='meetlokahTextDiv'>
                                Meet Lokah
                            </div>
                            <div class='meetlokahFlexDiv d-flex flex-column-reverse flex-md-row'>
                                <div class='meetLokahSubDivOne d-flex justify-content-center'>
                                    <div class='meetLokahSubDivOneChildDiv'>
                                        <div class='theworldLokah'>
                                            <span >The Best Of The Lokah </span>
                                        </div>
                                        <div class='theWordLokahDescription'>
                                            <p>Being the top H-1B consultant in Hyderabad, we have a history of closing at least Hundreds of profiles each year. By considering their needs, we encourage our applicants to apply for the respective visas without offering them any false hopes. Our transparency stands tall before them by making them look up to see and fly beyond the sky.</p>
                                        </div>

                                    </div>
                                </div>
                                <div class='meetLokahSubDivTwo d-flex justify-content-center align-items-center'>
                                    <img class='meetuplokahImg' src={meetuplokah} alt='not visible' />
                                </div>
                            </div>

                        </div>

                        <div class='ourServiceMainDiv d-flex flex-column align-items-center ' id='servicesdiv'>
                            <div class='ourServiceSubDiv'>
                                <span>Our Services</span>
                            </div>
                            <div class='homeCardsDiv d-flex flex-column flex-md-row align-items-center justify-content-md-around'>
                                <div class='cardsDivHomepage d-flex flex-column align-items-center'>
                                    <div class="homecardImgOneDiv">

                                    </div>
                                    <div class='cardstextmaindiv d-flex flex-column align-items-start'>
                                        <div class='cardsTextDiv'>
                                            H1B Transfer
                                        </div>
                                        <div class='cardParaDiv'>
                                            The H1B transfer procedure is comparable to the initial H1B visa application process and is basically used for shifting or making a transfer.
                                        </div>
                                        <div class='getstarted'>
                                            <a href='/H1Btransfer_page'>  <button class="GsBtn"><span>Get Started</span> <HiOutlineArrowSmRight /> </button></a>
                                        </div>
                                        <div class='cardButtonDiv'>

                                        </div>
                                    </div>
                                </div>
                                <div class='cardsDivHomepage d-flex flex-column align-items-center'>
                                    <div class="homecardImgTwoDiv">

                                    </div>
                                    <div class='cardstextmaindiv d-flex flex-column align-items-start'>
                                        <div class='cardsTextDiv'>
                                            Fresh H1B Filing
                                        </div>
                                        <div class='cardParaDiv'>
                                            The H1B nonimmigrant is permitted a 3-year stay in the United States, which can be extended, but not for longer than a total of 6-years.
                                        </div>
                                        <div class='getstarted'>
                                            <a href='/H1Bfiling_page'> <button class="GsBtn"><span>Get Started</span> <HiOutlineArrowSmRight /> </button></a>
                                        </div>
                                        <div class='cardButtonDiv'>

                                        </div>
                                    </div>
                                </div>
                                <div class='cardsDivHomepage d-flex flex-column align-items-center'>
                                    <div class="homecardImgThreeDiv">

                                    </div>
                                    <div class='cardstextmaindiv d-flex flex-column align-items-start'>
                                        <div class='cardsTextDiv'>
                                            Canada PR
                                        </div>
                                        <div class='cardParaDiv'>
                                            A common option for people seeking to immigrate to Canada is a Canada PR visa that enables permanent residency in the same.
                                        </div>
                                        <div class='getstarted'>
                                            <a href='/Canadapr_page'> <button class="GsBtn"><span>Get Started</span> <HiOutlineArrowSmRight /> </button></a>
                                        </div>
                                        <div class='cardButtonDiv'>

                                        </div>
                                    </div>
                                    <div class='cardButtonDiv'>

                                    </div>
                                </div>
                            </div>
                            <div class='homeCardsSecondDiv d-flex flex-column flex-md-row align-items-center justify-content-md-around'>
                                <div class='cardsDivHomepage d-flex flex-column align-items-center'>
                                    <div class="homecardImgFourDiv">
                                    </div>
                                    <div class='cardstextmaindiv d-flex flex-column align-items-start'>
                                        <div class='cardsTextDiv'>
                                            UK Work Permit
                                        </div>
                                        <div class='cardParaDiv'>
                                            The UK invites skilled individuals to work in the UK through the Tier 2 visa scheme to maintain its competitive edge.
                                        </div>
                                        <div class='getstarted'>
                                            <a href='/UKWork_page'>    <button class="GsBtn"><span>Get Started</span> <HiOutlineArrowSmRight /> </button></a>
                                        </div>
                                        <div class='cardButtonDiv'>

                                        </div>
                                    </div>
                                    <div class='cardButtonDiv'>

                                    </div>
                                </div>
                                <div class='cardsDivHomepage d-flex flex-column align-items-center'>
                                    <div class="homecardImgFiveDiv">
                                    </div>
                                    <div class='cardstextmaindiv d-flex flex-column align-items-start'>
                                        <div class='cardsTextDiv'>
                                            Student Visa
                                        </div>
                                        <div class='cardParaDiv'>
                                            One of the most profound and influential experiences is studying abroad and people need a student visa to access the studying options.
                                        </div>
                                        <div class='getstarted'>
                                            <a>   <button class="GsBtn"><span>Get Started</span> <HiOutlineArrowSmRight /> </button></a>
                                        </div>
                                        <div class='cardButtonDiv'>

                                        </div>
                                    </div>
                                    <div class='cardButtonDiv'>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* whylokah block start */}
            <div className=' container-fluid lokahBlock' id='whylokah'>
                <h1>Why Lokah?</h1>
                <div className='lokahBlockrevrse'>
                    <div class=' whyLokahBlock '>
                        <div ><img src={whyLokah} class='lokahBlockimg' /></div>
                        <div>
                            <ul class='whyLokahRightBlock'>
                                <li>The clint base which has the long history of American employement legitimacy and knowledge of our clints</li>
                                <li>Ethical and profissionalism</li>
                                <li>Handled nearly 3000+ applications till date</li>
                                <li>Transparent in processing visa applications</li>
                                <li>Coherent about first call impression</li>
                                <li>The turnaround time is pretty quick</li>
                                <li>Error free application process guaranteed</li>
                                <li>Organization of visa application documents</li>
                                <li>Personalized mock interview sessions</li>
                            </ul>
                        </div>
                    </div>
                    <div className='lokahBlockPassage'>
                        <p className='lokahBlockPassageone'>The US Dream: The US is the land of opportunities, the gigantic economy on the earth has always in demand of top-notch intellectuals such as engineers, doctors, multinational managers etc. One of the biggest secrets of America’s success in becoming leader in the world economy is its immigration policy. Over a century it has been attracting top brains from across the globe. H1 B VISA, a temporary work visa ,is one of the visa types providing in its strategy in supporting USA economy. The H1 B can only be applied by US based employer on behalf of their prospective foreign employee. Hence we work only with the reliable and trusted clints(Employer)  The process of obtaining H1 B visa involves – Department of labor certification (LCA) approval and submission of nonimmigrant work visa application (I-129 form) by the employer. Once it’s approved an approval notice (I-797) is issued with the US Department of immigration and Citizenship (USCIS). The prospective employee who is granted H1 B will have to obtain VISA stamping from US consulates in overseas. Upon granting VISA stamping the employee can begin work in the US for that employer.</p>
                    </div>
                </div>
            </div>
            <div className='container-fluid Testimonials'>
                <h1 className='TestimonialsHeading'>Testimonials</h1>

                <div className='TestimonialsBlock'>
                    <div className='TestimonialsLeft'>
                        <MdArrowBackIosNew className='TestimonialsLefticon' size={40} color='#003F66' />
                    </div>
                    <div className='Testimonialspassage'>
                        <img className='Testimonialsimg' src={testimonial} />
                        <img className='Testimonialsimg' src={testimonial} />
                        <p className='Testimonialspassageone'>I have a pharmacy postgraduate degree and I am an Indian citizen. I currently reside in Canada and travel frequently to the US on an H1B visa for business. I tried to obtain an H1B visa when I was in India. I did everything, but I was unable to obtain an H1B visa to work as a veterinarian in the USA. Then one day I discovered your website and heard about Canadian immigration and its permanent migration scheme. I came to you, and now I am a content permanent resident of Canada.</p>
                        <p className='TestimonialspassageTwo'><span style={{ paddingRight: '15px' }}><AiOutlineLine color='#1F2C51' size={40} /></span>Rakesh </p>
                    </div>
                    <div className='TestimonialsRight'>
                        <MdArrowForwardIos className='TestimonialsRighticon' size={40} color='#003F66' />
                    </div>
                </div>
            </div>
            {/* whylokah block end */}

            <div class='H1BFDocumentsRequired d-flex flex-column align-items-center'>
                <div class='d-flex justify-content-center  mt-3'>
                    <h2 class='H1BFDocReqHead'>Documents Required</h2>
                </div>
                <div class='H1BFdocumentsDataMainDIv d-flex flex-column-reverse flex-md-column align-items-center'>
                    <div class='H1BFDocumentReqMabdateDiv'>
                        <div class='d-flex  justify-content-start gap-2'>
                            <div class='d-flex flex-column align-items-start justify-content-between'>
                                <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                <img class='tickiconsCls' src={tickicon} alt='not visible' />
                                <img class='tickiconsCls' src={tickicon} alt='not visible' />
                            </div>
                            <div class='d-flex flex-column align-items-start gap-2 Mandate'>
                                <span class='mandateText'>Mandate 4years bachelor degree (Science/Medicine/Para medical/Engineering...)</span>
                                <span class='mandateText'>  Relevant licence required</span>
                                <span class='mandateText'>  Expext 5+ years of experience is most of our clints in respective Profissions</span>
                            </div>
                        </div>
                    </div>
                    <div class='H1BFdocumentTextMAinwarp d-flex flex-column flex-md-row justify-content-center align-items-center mt-4'>
                        <div class='H1BFdocumentTextwraponeDiv d-flex flex-column flex-md-row gap-2'>
                            <div class='H1BFdocumentTextwraponeSubDivone d-flex flex-column align-items-start'>
                                <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                    <div class='H1BFdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                        <span>Duration of Stay for H1B</span>
                                        <p> Initial Three Years, Extendable to Six Years indifinate after approving the Green Card Application i.e I140</p>
                                    </div>
                                </div>
                                <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                    <div class='H1BFdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                        <span>Family & Dependents</span>
                                        <p>H-1B workers' spouses and minor children are regarded as dependents and qualify for H-4 nonimmigrant status. Only when the primary H-1B beneficiary is present in the country in good standing H-1B status are H-4 beneficiaries permitted to be in H-4 status.</p>
                                    </div>
                                </div>
                                <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center mt-5'>
                                    <div class='H1BFdocumentsCardSubdiv d-flex flex-column align-items-md-center align-items-start'>
                                        <span>H1B Lottery System</span>
                                        <p>It Starts Mid of march Every Year, But 1st october is the Actual Fisical Year for the H1B quota</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div class='H1BFdocumentTextwraponeSubDivtwo'>
                                <div class='H1BFdocumentsCardOne d-flex  justify-content-center align-items-center'>
                                    <div class='H1BFdocumentsRequiredTextDiv d-flex flex-column align-items-md-start align-items-start' style={{ 'textAlign': 'left' }}>
                                        <span>Documents Required</span>
                                        <p>•	Passport</p>
                                        <p>•	Labor Certificate Approval</p>
                                        <p>•	Appointment Letter offered by your employer</p>
                                        <p>•	Tax returns paper of the company</p>
                                        <p>•	Copy of the letter sent to the Department of Justice </p>
                                        <p> •	Copy of the letter sent to the Consular General of the Indian Consulate</p>
                                        <p>•	Copy of the official valuation of your degrees</p>
                                        <p>•	UG certificate</p>
                                        <p>•	Appointment and Relieving Certificates from the previous companies that you were employed at.</p>
                                        <p>•	Dates of prior stay in U.S. in H1-B status</p>
                                        <p>•	Job description and duty with the sponsoring U.S. Company to be described in short.</p>
                                        <p>•	Copy of current U.S. License or temporary license</p>
                                        <p>•	Copy of resume with supporting certificates</p>
                                        <p>•	Two demand drafts, $45 for processing fee and $100 for issuance fee</p>
                                        <p>•	2 to 3 passport sized colored photographs</p>

                                        <p>•	If H-4 visa is being applied for dependent members, then you must submit copies of children's birth certificates and copy of marriage certificate.</p>


                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class='H1BFdocumentTextwrapTwoDiv'>
                            <img class='H1BFformImgSize' src={paperwork} alt='not viewed' />
                        </div>
                    </div>
                </div>
            </div>


            <div class='contactusMainDiv d-flex flex-column align-items-center' id='contactdiv'>
                <div class='contactusTextDiv d-flex justify-content-center align-items-center'>
                    <span>Contact Us</span>
                </div>
                <div class='contactusCardsMainDiv'>
                    <div class='row'>
                        <div class='col-6 col-md-4 d-flex-justify-content-center'>
                            <div class='contactusCardoneDiv d-flex justify-content-center'>
                                <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                    <img class='callanytime' src={callanytime} alt='not visible' />
                                    <span class='callAnytimeText'>Call any time</span>
                                    <p class='callAnytimeparagraph'>For immediate response reach out us at .</p>
                                    {/* <a href='tel:'><HiPhone color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>US : +1(570) 521-1612</span></a> */}
                                    <a href="https://wa.link/sqsle0" target="_blank"><BsWhatsapp color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                    <a href="tel:9963525491" target="_blank"><HiPhone color='#0896CC' style={{ marginRight: '15px' }} /><span style={{ color: '#0896cc' }}>IND : +91 9963525491</span></a>
                                    {/* <a href='https://wa.link/drdbtk' target='blank'><BsWhatsapp color='#0896CC'style={{marginRight:'15px'}} /><span style={{ color: '#0896cc' }}>IND : +91 9849374565</span></a> */}
                                </div>
                            </div>
                        </div>
                        <div class='col-6 col-md-4 columnscheckdiv d-flex-justify-content-center'>
                            <div class='contactusCardoneDiv d-flex justify-content-center'>
                                <div class='contactusCardoneSubDiv d-flex flex-column align-items-start'>
                                    <img class='callanytime' src={emailus} alt='not visible' />
                                    <span class='callAnytimeText'>Email Us</span>
                                    <p class='callAnytimeparagraph'>Ask us a question by email and we will respond immediately.</p>
                                    <a href='mailto:info@lokah.co.in'>info@lokah.co.in</a>
                                    <a href='mailto:hrinfo@lokah.co.in'>hrinfo@lokah.co.in</a>

                                </div>
                            </div>

                        </div>
                        <div class='col-12 col-md-4 columnscheckdiv d-flex justify-content-center  '>
                            <div class='contactusCardTwoDiv d-flex justify-content-center'>
                                <div class='contactusCardoneSubDiv d-flex flex-column align-items-start '>
                                    <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank"> <img class='callanytime' src={officeaddress} alt='not visible' /></a>
                                    <span class='callAnytimeText'>Office Address</span>
                                    <p class='callAnytimeparagraph '>Visana Residency, Flat No. 503,</p>
                                    <p class='callAnytimeparagraph'> Krishna Sai Enclave Near Maktha,</p>
                                    <p class='callAnytimeparagraph'> JP Nagar, Miyapur,</p>
                                    <p class='callAnytimeparagraph'> Hyderabad,500049.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class='contactusSubDiv d-flex flex-column-reverse flex-md-row'>
                    <div class='contactusGirlmainDiv'>

                    </div>
                    <a href='https://goo.gl/maps/GHqAXGk8CDjsdii39' target="_blank" class='contactusMapmainDiv'>

                    </a>
                </div>
            </div>


            {/* Faq block */}

            <div className='documentsBlocl'>

                <div className='questionsBlock'>
                    <h2 className=''>Frequently Asked Questions</h2>

                    <div className="tab">

                        <ul className='H1B'>
                            <a href="#link1"  >
                                <li ><a className='question' style={{ textDecoration: 'none' }}>What is H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link1">
                                    <p>A Person in Specialty Occupation Visa is another name for the H1B visa. It means that if a US Employer accepts you for a particular job post, you are eligible for the H1B visa. </p>
                                </div>
                            </a>
                        </ul>
                    </div>
                </div>


                <div className='questionsBlock'>

                    <div className=" tab">
                        <ul className='H1B' >
                            <a href="#link2"  >
                                <li ><a className='question' style={{ textDecoration: 'none' }}>Who initiates H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link2">
                                    <p>An employer in the United States requests the H1B work visa. The business must have an open position and be unable to locate an American worker who is capable of finishing the work. Any position that needs advanced degrees or is sufficiently specialized in skills that few people can perform it successfully falls under this category.</p>
                                </div>
                            </a>
                        </ul>
                    </div>
                </div>

                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link4">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>Who can apply for H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link4">

                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Holding a degree from a prestigious university, such as a four-year bachelor's (or equivalent degrees)</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	A doctoral or master's degree</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Advanced education or practical experience </p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} /> Meet the requirements to work on US Department of Defense research and development programs or in other government jobs.</p>

                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B' >
                            <a href="#link5">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>How to apply for H-1B Visa? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link5">
                                    <p>The actions you must do in order to apply for an H1B visa are listed below:</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Make sure you are H1B visa eligible.</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	By submitting an application for a position in the US, you can locate a petitioner.</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Have your employer be the one to start the petition.</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Visit the closest US Embassy or Consulate in your nation to submit an application for an H1B visa.</p>



                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link6">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>How to file an H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link6">
                                    <p>The procedures that employers must follow in order to petition a foreign worker are as follows:</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	An application for labor conditions (LCA).</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Petition to USCIS</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Finish the I-129 form.</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Send in the paperwork and the forms.</p>
                                    <p> <GoPrimitiveDot className="subAnswers" style={{ paddingRight: '10px' }} />	Attend to the USCIS review.</p>

                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link7">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>What is H-1B Lottery?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link7">
                                    <p>In March, your employer has two weeks to file an H1B petition with the USCIS. (The H1B lottery for 2021 began on March 9 and ran through March 25.) Following submission, the USCIS processed the petition using a digital random selection technique to choose 85,000 individuals at random.</p>
                                    <p>The US grants 20,000 H1B visas to candidates with master's degrees or higher and 65,000 standard H1B visas.</p>
                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link8">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>How long do I stay in the US with H-1B Visa? </a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link8">
                                    <p>The H1B visa has a three-year initial validity period and a three-year extension period. A person may only reside and work in the United States for a total of six years. The individual need not, however, go back to their nation of origin. The fact that the H1B visa has two purposes is what makes it so well-liked. </p>

                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link9">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>How to extend the H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link9">
                                    <p>The processes for H1B extensions are the same as those for initial applications. With the exception of the Fraud Prevention and Protection Fee, you will be required to pay the same fees. The same steps must be taken by your company in order to obtain DOL certification and a USCIS-approved petition.</p>

                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link9">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>Can I bring my partner with me through H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link9">
                                    <p>Many people with H1B visas choose to travel with their partners and kids. If they are granted a H 4 visa, spouses are allowed to travel with H1B visa holders. Since 2015, individuals with H-4 visas may be capable of working under the H4 EAD program under specific circumstances. Additionally, persons with H-4 visas are permitted to enroll in academic programs.</p>

                                </div>
                            </a>
                        </ul>
                    </div>
                </div>
                <div className='questionsBlock'>
                    <div className=" tab">
                        <ul className='H1B'>
                            <a href="#link9">
                                <li ><a className='question' style={{ textDecoration: 'none' }}>How to transfer my H-1B Visa?</a><RiArrowDropDownFill size={40} color={'#343434'} /> </li>
                                <div id="link9">
                                    <p>You can request an H1-B transfer to another employer. However, you must have a job that is legal in the US. Additionally, the most recent W2 forms and pay statements are required.</p>
                                    <p>If you don't have current pay stubs, you'll need to give USCIS an explanation for why you don't have them. It might be related to extended unpaid sick leave or vacation. It is necessary to produce the letter of approval for the same. Even if you hold a current H1-B Visa or an active I-94 card, your H1-B status will be lost if you lose your work.</p>
                                </div>
                            </a>
                        </ul>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Home