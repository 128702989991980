import React from 'react'
import telephone from '../Assets/te.png'
import '../Components/telephone.css'

function Telephone() {
  return (
    <div  class='telephoneMainDiv'>
      <a href='tel:9963525491'>  <img class='telephoneImg' src={telephone}/> </a>
    </div>
  )
}

export default Telephone