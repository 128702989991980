
import React from 'react'
import './Footer.css';
import logo from '../../Assets/lokahlogo.png';
import fbfoot from '../../Assets/fbfoot.png'
import { BsTelephoneFill } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { MdLocationOn } from 'react-icons/md';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillYoutube } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';


const Footer = () => {
    return (
        <div>
            <div className="  footerMain">
                <div className=" footerblock">
                    <div className="  footerLogo">
                        <a href='/'> <img src={logo} /></a>
                    </div>
                    <div className='footerBlockTwo'>
                        <div className=" footerHeadings footerHome " >
                            <a href='/' style={{ textDecoration: 'none' }}> <p>Home</p></a>

                        </div>

                        {/* <div className=" footerHeadings footerAboutUs ">
                            <p>About Us</p>
                            <ul className='footerList orderedLi'>
                                <li>Terms of Use</li>
                                <li>Privacy Statement </li>
                                <li>Cookie Policy</li>
                                <li>FAQs</li>

                            </ul>
                        </div> */}
                        <div className=" footerHeadings footerService ">
                            <p>Service</p>
                            <ul className='footerList orderedLi'>
                                <a href='/H1Bfiling_page' style={{ textDecoration: "none" }}> <li>H1b filing</li></a>
                                <a href='/H1Btransfer_page' style={{ textDecoration: "none" }}>  <li>H1b transfer </li></a>
                                <a href='/Canadapr_page' style={{ textDecoration: "none" }}>  <li>Canada pr</li></a>
                                <a href='/UKWork_page' style={{ textDecoration: "none" }}>  <li>UK work permit</li></a>
                                <a href='' style={{ textDecoration: "none" }}>  <li>Student visa</li></a>
                            </ul>
                        </div>
                        <div className=" footerHeadings footerContact">
                            <p>Contact</p>
                            <ul className='footerList orderedLi'>
                                {/* <li><BsTelephoneFill size={15} color='white' style={{ marginRight: '10px' }} />US : +1(570) 521-1612</li> */}

                                <a href="tel:9963525491" target='blank' style={{ textDecoration: 'none' }}>  <li><BsTelephoneFill size={15} color='white' style={{ marginRight: '10px' }} />IND : +91 9963525491</li></a>
                                <a href='mailto:info@lokah.co.in' target='blank' style={{ textDecoration: 'none' }}> <li><GrMail size={15} color='white' style={{ marginRight: '10px' }} /> info@lokah.co.in</li></a>
                                <a href='mailto:hrinfo@lokah.co.in' target='blank' style={{ textDecoration: 'none' }}> <li><GrMail size={15} color='white' style={{ marginRight: '10px' }} /> hrinfo@lokah.co.in</li></a>
                                <li style={{ paddingBottom: '0px' }}><MdLocationOn size={15} color='white' style={{ marginRight: '10px' }} />Visana Residency,</li>
                                <li style={{ paddingBottom: '0px', paddingLeft: '27px' }} >Flat No. 503,</li>
                                <li style={{ paddingBottom: '0px', paddingLeft: '27px' }} >Krishna Sai Enclave  </li>
                                <li style={{ paddingBottom: '0px', paddingLeft: '27px' }}> Near Maktha.JP Nagar,Miyapur,</li>
                                <li style={{ paddingBottom: '0px', paddingLeft: '27px' }}>Hyderabad,500049.</li>

                            </ul>
                        </div>
                    </div>
                    <div className="  footerHeadings  footerSocialNetwork">
                        <p>Our Social Networks</p>
                        <ul className='footerList   SocialNetwork'>
                            <a href='https://www.facebook.com/LokahH1Bvisa' target='blank' > <li><FaFacebookF size={23} color='	#4267B2' /></li></a>
                            <a href=' ' target='blank'>    <li><AiFillYoutube size={23} color='red' /></li></a>
                            <a href='https://www.linkedin.com/company/lokah-h1b-visa/' target='blank'>  <li><FaLinkedinIn size={23} color='#0072b1' /></li></a>
                            <a href='https://www.instagram.com/lokahh1b/' target='blank'>   <li><RiInstagramFill size={23} color=' #8a3ab9' /></li></a>

                        </ul>
                    </div>


                </div>
                <div className='seondFooter'>
                    <div className='col seondFootertxt'>

                        <p>© Copyright LOKAH. All Rights Reserved</p>

                    </div>
                    <div className='col seondFootertxt'>

                        <p>TOTAL VISITORS : <a href="https://www.freecounterstat.com" title="page counters"><img src="https://counter9.stat.ovh/private/freecounterstat.php?c=6pfwme3qxe2pf9f273k98j9zy8fwwssu" border="0" title="page counters" alt="page counters" /></a></p>


                    </div>
                </div>
            </div>


        </div>


    )
}
export default Footer
